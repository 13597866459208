// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-ferie-js": () => import("./../src/pages/ferie.js" /* webpackChunkName: "component---src-pages-ferie-js" */),
  "component---src-pages-ferie-2024-js": () => import("./../src/pages/ferie2024.js" /* webpackChunkName: "component---src-pages-ferie-2024-js" */),
  "component---src-pages-ferie-2025-js": () => import("./../src/pages/ferie2025.js" /* webpackChunkName: "component---src-pages-ferie-2025-js" */),
  "component---src-pages-galeria-js": () => import("./../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-grafik-js": () => import("./../src/pages/grafik.js" /* webpackChunkName: "component---src-pages-grafik-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-js": () => import("./../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-wakacje-js": () => import("./../src/pages/wakacje.js" /* webpackChunkName: "component---src-pages-wakacje-js" */),
  "component---src-pages-wakacje-2023-js": () => import("./../src/pages/wakacje2023.js" /* webpackChunkName: "component---src-pages-wakacje-2023-js" */),
  "component---src-pages-wakacje-2024-js": () => import("./../src/pages/wakacje2024.js" /* webpackChunkName: "component---src-pages-wakacje-2024-js" */),
  "component---src-pages-zapisy-js": () => import("./../src/pages/zapisy.js" /* webpackChunkName: "component---src-pages-zapisy-js" */)
}

